<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="hotels"
                          columnsPrefix="hotel.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="hotel.title.hotels"
                          :actions-list="getActions()"
                >
                    <template #cell(actions)="row">
                        <b-button
                            @click="$root.$children[0].openModal('hotel-modal', {id:row.item.id},refreshTable)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>

                        <b-button @click="deleteHotel(row.item.id)"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Hotels',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getSettings']),
        ...mapGetters('Hotels', ['getHotelsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Hotels/fetchHotels', 'getHotelsTable');
        },
        deleteHotel(id) {
            this.$removeConfirm('Hotels/deleteHotel', id, this.refreshTable)
        },
        openImport() {
            this.$root.$children[0].openModal('import-modal', {type: 'hotel'}, this.refreshTable, {width: '800px'})
        },
        exportToWp() {
            this.$root.$children[0].loading = true
            fetch(this.getSettings()['wordpress_sync_url']).finally(() => {
                this.$root.$children[0].loading = false
            })
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('hotel-modal', {id: 0}, this.refreshTable)
                    }
                },
                {
                    title: this.$t('hotel.button.export_to_wp'),
                    icon: 'cloud-download-alt',
                    click: () => {
                        this.exportToWp()
                    }
                },
                {
                    title: this.$t('common.button.import'),
                    icon: 'download',
                    click: () => {
                        this.openImport()
                    }
                },
            ]
        }
    },
}
</script>